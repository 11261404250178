body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: black;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  text-align: center;
}

.button-container {
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .App {
    background-image: url('./img/mobile.jpg');
  }
}

@media (min-width: 1025px) {
  .App {
    background-image: url('./img/desktop.jpg');
  }
}
